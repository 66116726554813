.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: $primary;
}

.test-case-selection-dropdown-on-defect {
  .ant-select-item-option .txt-alt {
    font-weight: 500;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: normal;
  }
}
.test-case-selection-on-defect .txt-name {
  display: none;
}
