.ant-btn {
  &:hover,
  &:focus {
    color: $primary;
    border-color: $primary;
  }
  &.no-border-on-focus {
    &:focus:not(:hover) {
      border-color: transparent !important;
    }
  }
}
.ant-btn-primary {
  background: $primary;
  border-color: $primary;
  &:hover,
  &:focus {
    color: #fff;
    background: darken($primary, 15%);
    border-color: darken($primary, 15%);
  }
  &.success {
    background: $success;
    border-color: $success;
    &:hover,
    &:focus {
      color: #fff;
      background: darken($success, 15%);
      border-color: darken($success, 15%);
    }
  }
}
.ant-btn-link {
  color: $primary;
  &:hover,
  &:focus {
    color: $primary;
    border-color: $primary;
  }
}
.ant-btn-background-ghost.ant-btn-primary {
  color: $primary;
  border-color: $primary;
  &:hover,
  &:focus,
  &:active {
    color: darken($primary, 15%);
    border-color: darken($primary, 15%);
  }
}
