.ant-progress {
  &.progress-danger {
    &.expired {
      opacity: 0.5;
    }
    .ant-progress-inner {
      background: $danger;
    }
    .ant-progress-text {
      color: $danger;
    }
  }
  &-success-bg,
  &-bg {
    background: $primary;
  }
}
