.ant-tabs {
  color: $body-color;
  body.dark & {
    color: $body-dark-color;
  }
  &.ant-tabs-nav-mb-2 .ant-tabs-nav {
    margin-bottom: 0.5rem;
  }
  &.tabs-link {
    .ant-tabs-tab {
      padding: 0;
      .ant-tabs-tab-btn {
        &:hover {
          color: $primary;
        }
        > * {
          display: block;
          background: none;
          border: 0;
          outline: 0;
          padding: 12px 0;
        }
      }
    }
  }
  .ant-tabs-tab {
    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: $primary;
      }
    }
    &.ant-tabs-tab-disabled {
      .ant-tabs-tab-btn {
        color: rgba(0, 0, 0, 0.25) !important;
        cursor: not-allowed;
      }
    }
    .ant-tabs-tab-btn {
      &,
      &:hover {
        color: $body-color;
        body.dark & {
          color: $body-dark-color;
        }
      }
      > * {
        color: inherit;
      }
    }
  }
}
.ant-tabs-ink-bar {
  background: $primary;
}
