.anticon {
  svg {
    width: 1em;
    height: 1em;
  }
  &.gray {
    color: rgba(0, 0, 0, 0.45);
    body.dark & {
      color: rgba(255, 255, 255, 0.68);
    }
  }
  &.ic-bug-searching {
    path,
    circle,
    line {
      fill: none;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 10;
    }
  }
}
