.basic-badge {
  display: inline-block;
  vertical-align: middle;
  color: $not-executed;
  border: 1px solid $not-executed;
  border-radius: 4px;
  outline: 0;
  box-shadow: none;
  text-align: center;
  padding: 0 15px;
  &.badge-not-executed {
    color: #131712;
    border-color: #131712;
  }
  &.badge-pass-status {
    color: $pass-status;
    border-color: $pass-status;
  }
  &.badge-fail-status {
    color: $fail-status;
    border-color: $fail-status;
  }
  &.badge-wip-status {
    color: $wip-status;
    border-color: $wip-status;
  }
  &.badge-blocked-status {
    color: $blocked-status;
    border-color: $blocked-status;
  }
  &.badge-gray {
    color: $body-color;
    border-color: $border-color;
    body.dark & {
      color: $body-dark-color;
      border-color: $border-dark-color;
    }
  }
}
