.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: $primary;
}
.ant-steps-item-process .ant-steps-item-icon {
  border-color: $primary;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: $primary;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: $primary;
}
.ant-steps-item-icon .ant-steps-icon {
  color: $primary;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: $primary;
}
