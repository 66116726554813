.c-directory-tree {
  .ant-tree {
    color: rgba(0, 0, 0, 0.85);
    body.dark & {
      color: $body-dark-color;
    }
    .ant-tree-treenode {
      &:hover {
        .btn-dots-option {
          opacity: 1;
        }
      }
      .ant-tree-node-content-wrapper {
        flex: 1 1 calc(100% - 24px - 24px - 24px - 24px);
        width: calc(100% - 24px - 24px - 24px - 24px);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        padding-right: 0;
        .anticon {
          color: $primary;
          transition: none;
        }
        &.ant-tree-node-selected {
          .anticon {
            color: #fff;
          }
        }
      }
      .ant-tree-iconEle {
        flex: 0 0 30px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        .anticon {
          font-size: 20px;
          padding-top: 5px;
        }
      }
      .ant-tree-title {
        flex: 1 1 calc(100% - 30px);
        width: calc(100% - 30px);
        line-height: 1.5;
        padding-left: 3px;
        padding-right: 24px;
      }
      .btn-option {
        display: block;
        position: absolute;
        left: 0;
        right: auto;
        top: 0;
        bottom: auto;
        width: calc(100% - 24px);
        height: 100%;
        color: $body-color;
        background: none;
        border: 0;
        outline: 0;
        box-shadow: none;
        transition: none;
        padding-right: 7px;
        &:hover {
          color: $primary;
        }
        &:after {
          display: none;
        }
        body.dark & {
          color: $body-dark-color;
          &:hover {
            color: $primary;
          }
        }
        &.ant-dropdown-open {
          opacity: 1;
        }
        .invisible {
          visibility: hidden;
          opacity: 0;
          position: absolute;
          width: 0;
          height: 0;
        }
      }
      .btn-dots-option {
        opacity: 0;
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 24px;
        height: 30px;
        color: $body-color;
        background: none;
        border: 0;
        outline: 0;
        box-shadow: none;
        text-align: center;
        transition: color 0.4s ease;
        z-index: 2;
        &:hover {
          color: $primary;
        }
        &:after {
          display: none;
        }
        body.dark & {
          color: $body-dark-color;
          &:hover {
            color: $primary;
          }
        }
        &.ant-dropdown-open {
          opacity: 1;
        }
        .anticon {
          color: inherit !important;
        }
      }
    }
    .ant-tree-treenode {
      width: 100%;
      padding-bottom: 0;
      margin-bottom: 4px;
      &:before {
        top: 0;
        bottom: 0;
      }
      &.against-style-drag:not(.ant-tree-treenode-selected) {
        .ant-tree-node-content-wrapper.ant-tree-node-selected.draggable {
          color: rgba(0, 0, 0, 0.85);
          .anticon {
            color: $primary;
          }
        }
      }
      &.drag-over-gap-top > [draggable] {
        border-top-color: transparent;
      }
      &.drag-over-gap-bottom > [draggable] {
        border-bottom-color: transparent;
      }
      &.drag-over > [draggable] {
        background-color: $primary;
      }
    }
    .ant-tree-treenode-selected {
      &,
      &:hover {
        background: $primary;
      }
      &:hover {
        .btn-dots-option {
          color: #fff;
          &:hover {
            color: $body-color;
          }
          body.dark & {
            color: $body-dark-color;
          }
        }
      }
      &::before {
        display: none;
      }
    }
    .ant-tree-switcher {
      line-height: 30px;
      .ant-tree-switcher-icon,
      .ant-select-tree-switcher-icon {
        font-size: 12px;
      }
    }
    .ant-tree-checkbox {
      margin-top: 7px;
    }
    .ant-tree-node-content-wrapper {
      min-height: 30px;
      line-height: 30px;
      padding-left: 0;
    }
    .ant-tree-drop-indicator {
      display: none;
    }
  }
  &.no-btn-option {
    .ant-tree {
      .ant-tree-treenode {
        .ant-tree-node-content-wrapper {
          padding-right: 0;
        }
      }
    }
  }
  .input-filter {
    .ant-input-suffix {
      .anticon {
        color: rgb(59, 59, 59);
      }
    }
  }
  .ant-select {
    .ant-input {
      &::-moz-placeholder {
        font-style: italic;
      }
      &::-webkit-input-placeholder {
        font-style: italic;
      }
      &:-moz-placeholder {
        font-style: italic;
      }
      &:-ms-input-placeholder {
        font-style: italic;
      }
    }
  }
}
.c-tree-option-dropdown {
  &.slide-up-leave {
    display: none !important;
  }
  .ant-dropdown-menu-item {
    padding: 0;
    .ant-btn {
      min-width: 180px;
      border-color: transparent;
      text-align: left;
    }
  }
}
