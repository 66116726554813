.c-tree-and-list {
  position: relative;
  .row-wrapper {
    min-height: calc(100vh - 232px);
  }
  .col-tree {
    flex: 0 0 300px;
    max-width: 300px;
    @include mq(max_md) {
      margin-bottom: 20px;
    }
  }
  .col-content {
    flex: 0 0 calc(100% - 300px);
    max-width: calc(100% - 300px);
  }
  .main-table {
    table {
      min-width: 800px;
    }
  }
  .c-top-tool-buttons {
    &.show-quick-add-form {
      .button-wrap {
        visibility: hidden;
        overflow: hidden;
        height: 0;
        margin-bottom: 0;
      }
      .quick-add-form {
        visibility: visible;
        overflow: visible;
        height: auto;
      }
    }
    &:not(.show-quick-add-form) {
      .quick-add-form {
        margin-bottom: 0;
      }
    }
    .button-wrap .ant-btn,
    .quick-add-form {
      margin-bottom: 0.5rem;
    }
    .button-wrap {
      .ant-btn {
        margin-right: 5px;
      }
    }
    .quick-add-form {
      position: relative;
      visibility: hidden;
      overflow: hidden;
      height: 0;
      .ant-input {
        padding-left: 0;
        padding-right: 60px;
      }
      .box-action-quick-add {
        position: absolute;
        right: 0;
        top: 4px;
        z-index: 2;
        .ant-btn {
          font-size: 0.9em;
        }
      }
    }
  }
  .c-view-folder-detail ,
  .c-view-search-detail {
    .main-table {
      table {
        min-width: 0;
      }
      .ant-table-pagination > li {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
    .anchor-link-on-detail-item {
      margin-bottom: 10px;
    }
  }
  .c-view-folder-detail  {
    &.is-show-tree {
      .col-tree-on-left,
      .col-table-on-left {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    &.is-hide-tree {
      .col-tree-on-left {
        overflow: hidden;
        visibility: hidden;
        max-width: 0;
        padding: 0;
      }
      .col-table-on-left {
        padding-left: 0;
      }
    }
    .col-tree {
      > .ant-row {
        height: 100%;
      }
    }
    .input-filter {
      padding: 5px;
      margin-bottom: 5px !important;
    }
    .col-tree-on-left {
      padding-right: 5px;
      padding-left: 5px;
    }
    .col-tree {
      position: relative;
      padding-left: 50px;
    }
    .c-directory-tree {
      height: 100%;
      box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.10);
    }
    .box-toggle-tree {
      position: absolute;
      left: 0;
      top: 0;
      width: 50px;
      height: 100%;
      text-align: center;
      background: #f6f6f6;
      cursor: pointer;
      padding: 10px 3px;
      .btn-toggle-tree {
        .anticon {
          display: block;
          margin-bottom: -1em;
        }
        .ic-tree-folder svg {
          width: 34px;
          height: 34px;
        }
      }
      .txt {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        width: 1px;
        font-weight: 500;
        span {
          display: block;
          position: absolute;
          right: 0;
          top: 0;
          white-space: nowrap;
          transform: rotate(-90deg);
          transform-origin: right center;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
      }
    }
  }
  .c-view-search-list {
    .col-tree {
      display: none;
    }
    .col-content {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      padding-left: 0 !important;
    }
    .ant-table-cell.map-requirement {
      display: none;
    }
  }
}
.top-action-on-detail-item {
  position: relative;
  &.is-on-modal {
    .inner-top-action {
      position: absolute;
      right: 60px;
      top: -40px;
      padding-left: 120px;
    }
  }
  &:not(.is-on-modal) {
    .inner-top-action {
      padding: 0 0 10px;
      margin-bottom: -20px;
    }
  }
  .inner-top-action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .txt-count {
    white-space: nowrap;
    margin-left: 15px;
  }
  .btns-prev-next {
    white-space: nowrap;
    margin-left: 5px;
    .ant-btn {
      width: auto;
      height: auto;
      font-size: 1.1em;
      color: $body-color;
      border-color: transparent;
      padding: 0;
      &:last-child {
        margin-left: 2px;
      }
      &:hover {
        color: $primary;
      }
      body.dark & {
        color: $body-dark-color;
        &:hover {
          color: $primary;
        }
      }
      &[disabled] {
        color: rgba(0, 0, 0, 0.25);
        body.dark & {
          color: rgba(108, 108, 108, 0.65);
        }
      }
    }
  }
  .ant-affix {
    left: 0 !important;
    width: 100% !important;
    background: #fff;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.10);
    .inner-top-action {
      padding: 5px 15px;
      margin-bottom: 0;
    }
  }
  .anchor-link {
    text-align: right;
    a {
      display: inline-block;
      vertical-align: middle;
      text-decoration: underline;
      margin-left: 10px;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .btn-reload {
    padding: 0;
    width: auto;
    height: auto;
    color: rgba(0, 0, 0, 0.45);
    border: 0;
    margin-left: 10px;
    z-index: 2;
    &:hover {
      color: $primary;
    }
    body.dark & {
      color: $body-dark-color;
      &:hover {
        color: $primary;
      }
    }
  }
}
