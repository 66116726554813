.c-left-sidebar {
  .ant-menu {
    height: 100%;
    border-right: 0;
    &.ant-menu-inline-collapsed {
      .ant-menu-item .anticon,
      .ant-menu-submenu-title .anticon {
        font-size: 20px;
      }
    }
    .ant-menu-submenu {
      &.ant-menu-submenu-selected {
        color: $primary;
      }
    }
    .ant-menu-sub {
      background: none;
    }
    .ant-menu-item {
      &.ant-menu-item-selected {
        background: transparent;
        .anticon,
        a {
          color: $primary;
        }
      }
    }
    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
      background: transparent;
    }
  }
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    font-size: 20px;
  }
  .ant-layout-sider-trigger {
    height: 43px;
    line-height: 43px;
    background: #fff;
    body.dark & {
      background: #fff;
    }
  }
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal).ant-menu-submenu-popup .ant-menu-item-selected {
  background: transparent;
  .anticon,
  a {
    color: $primary;
  }
}
