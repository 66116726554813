.ant-pagination-item {
  &:focus,
  &:hover,
  &-active {
    border-color: $primary;
    a {
      color: $primary;
    }
  }
}
.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: $primary;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: $primary;
  border-color: $primary;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  body.dark & {
    color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
    border-color: #434343;
  }
}
