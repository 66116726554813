@import "../assets/scss/variables";
@import "../assets/scss/mixins";

.l-default {
  max-width: 1700px;
  margin: auto;
  .main-body {
    min-height: calc(100vh - #{$header-height} - #{$footer-height});
    display: flex;
    flex-direction: row;
  }
  .main-content {
    padding: 0 0 15px 15px;
    background: #fff;
  }
}
.l-login{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

.l-login {
  .col-login-img {
    background: #f4f6f8 url("/images/login-layout.jpg") no-repeat center center;
    background-size: cover;
    @include mq(max_md) {
      display: none;
    }
    .img-logo {
      padding-top: 36px;
      padding-left: 10%;
    }
  }
  .col-login-contents {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    min-width: 500px;
  }
  .top-action {
    position: absolute;
    right: 0;
    top: 10px;
    width: 100%;
    text-align: right;
    padding: 0 10px;
    z-index: 10;
  }
}

.l-blank {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #f4f6f8;
  .btn-flag {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;
  }
}

.l-user {
  .main-body {
    min-height: calc(100vh - #{$header-height} - #{$footer-height});
  }
  .main-content {
    background: #fff;
    padding: 10px 15px 15px;
  }
}

.l-profile {
  .main-body {
    min-height: calc(100vh - #{$header-height} - 53px);
  }
  .main-content {
    background: #fff;
    padding: 10px 15px 15px;
  }
}
.ant-layout {
  margin-top: 2px;
}
