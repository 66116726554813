.collapse-item {
  .collapse-body {
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
    &.show {
      visibility: visible;
      max-height: 10000px;
      overflow: visible;
    }
  }
}
