.ant-modal {
  &-title {
    color: $primary;
  }
  &.p-0-modal-body {
    .ant-modal-body {
      padding: 0;
    }
  }
  &.ant-modal-confirm {
    .ant-modal-confirm-btns {
      margin-top: 20px;
      .ant-btn {
        min-width: 100px;
        margin: 3px 0 3px 8px;
      }
    }
  }
  .title-modal {
    position: relative;
    .btn-reload {
      position: absolute;
      right: 35px;
      top: 50%;
      transform: translate(0, -50%);
      padding: 0;
      width: auto;
      height: auto;
      color: rgba(0, 0, 0, 0.45);
      border: 0;
      z-index: 2;
      &:hover {
        color: $primary;
      }
      body.dark & {
        color: $body-dark-color;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}
.ant-modal.zoom-enter,
.ant-modal.zoom-appear,
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  animation-duration: 0.2s;
}
.modal-footer-common .ant-modal-footer,
.modal-footer-common.ant-modal-footer {
  text-align: center;
  .ant-btn {
    min-width: 100px;
    margin: 0 0.5rem;
  }
}
.modal-fixed-header { // Enable "centered" mode, wrap content by class "modal-body-with-scroll"
  overflow: hidden;
  .ant-modal {
    padding-bottom: 0;
  }
  .modal-body-with-scroll {
    max-height: calc(100vh - 55px);
    overflow-y: auto;
  }
}
