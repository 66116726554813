// -------------------- Variable -------------------- //

// ---------- Breakpoint => https://www.antdv.com/components/layout/#breakpoint-width
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

// ---------- Color
$primary: #4285f4;
$success: #28a745;
$warning: #faad14;
$danger: #ff4d4f;
$skyblue: #03c5ff;
$yellow-star: #f4c261;
$orange: #ff8c00;
$body-color: rgba(0, 0, 0, 0.85);
$body-dark-color: rgba(255, 255, 255, 0.85);;
$border-color: #d9d9d9;
$border-dark-color: #303030;
$not-executed: #b7b49e;
$pass-status: #76b000;
$fail-status: #cc3202;
$wip-status: #f1b100;
$blocked-status: #99ccff;

$identity-color: #004e24;

// Size
$header-height: 80px;
$footer-height: 75px;
$title-size: 16px;
$title-sub-size: 14px;

// ---------- Spacing
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2)
  ),
  $spacers
);
