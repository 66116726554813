@import "../../assets/scss/variables";
@import "../../assets/scss/mixins";

.c-release-top-buttons {
  margin-bottom: 15px;
  position: relative;
  .ant-btn {
    > .anticon + span,
    > span + .anticon {
      margin-left: 3px;
    }
  }
  .go-to-release:not(.ant-select-open) .ant-select-selection-placeholder {
    color: $body-color;
    body.dark & {
      color: $body-dark-color;
    }
  }
}