.d-block {
  display: block !important;
}
.d-none {
  display: none !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.position-relative {
  position: relative !important;
}
.w-100 {
  width: 100% !important;
}
.w-200 {
  width: 200px !important;
}
.min-w-100 {
  min-width: 100px !important;
}
.min-w-120 {
  min-width: 120px !important;
}
.min-w-150 {
  min-width: 150px !important;
}
.min-w-200 {
  min-width: 200px !important;
}
.min-w-175-xl {
  @include mq(xl) {
    min-width: 175px !important;
  }
}
.max-w-200 {
  max-width: 200px !important;
}
.max-w-270 {
  max-width: 270px !important;
}
.max-w-300 {
  max-width: 300px !important;
}
.max-w-400 {
  max-width: 400px !important;
}
.max-w-500 {
  max-width: 500px !important;
}
.max-w-700 {
  max-width: 700px !important;
}
.width-auto {
  width: auto !important;
}
.h-100 {
  height: 100% !important;
}
.min-h-22 {
  min-height: 22px !important;
}
.min-h-200 {
  min-height: 200px !important;
}
.height-auto {
  height: auto !important;
}
.text-body {
  color: $body-color !important;
  body.dark & {
    color: $body-dark-color !important;
  }
}
.text-primary {
  color: $primary !important;
}
.text-success {
  color: $success !important;
}
.text-warning {
  color: $warning !important;
}
.text-danger {
  color: $danger !important;
}
.text-skyblue {
  color: $skyblue !important;
}
.text-yellow-star {
  color: $yellow-star !important;
}
.text-orange {
  color: $orange !important;
}
.text-gray {
  color: #b0b0b0 !important;
}
.text-hover-primary {
  transition: all 0.4s ease;
  &:hover {
    color: $primary !important;
  }
  body.dark & {
    &:hover {
      color: $primary !important;
    }
  }
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-md-right {
  @include mq(md) {
    text-align: right !important;
  }
}
.text-center {
  text-align: center !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-pre-line {
  white-space: pre-line !important;
}
.text-italic {
  font-style: italic !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-has-right-line {
  overflow: hidden;
  span {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-right: 15px;
    &:after {
      display: block;
      content: '';
      position: absolute;
      left: 100%;
      top: 50%;
      width: 9000px;
      height: 1px;
      background: $border-color;
    }
  }
}
.text-truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.font-weight-medium {
  font-weight: 500 !important;
}
.font-weight-bold {
  font-weight: bold !important;
}
.color-inherit {
  color: inherit !important;
}
.bg-transparent {
  background: transparent !important;
}
.bg-deleted {
  background: #ffe7eb !important;
  .ant-form-item-control {
    body.dark & {
      color: #000 !important;
    }
  }
}
.bg-modified {
  background: #deffdd !important;
  .ant-form-item-control {
    body.dark & {
      color: #000 !important;
    }
  }
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-move {
  cursor: move !important;
}
.cursor-text {
  cursor: text !important;
}
.border-transparent {
  border-color: transparent !important;
}
.border-0 {
  border: 0 !important;
}
.border-bottom-1 {
  border-bottom: 1px solid $border-color !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.over {
  transition: all 0.4s ease;
  &:hover {
    opacity: 0.7;
  }
}
.list-style-none {
  list-style: none !important;
}
.vertical-align-middle {
  vertical-align: middle;
}
.box-description-style {
  width: 300px;
  max-height: 100px;
  overflow: auto;
}

// ------------------------- Spacing
// // Example
// .mb-1 {
//   margin-bottom: 0.25rem !important;
// }
@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
    .#{$abbrev}t-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
    }
    .#{$abbrev}r-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
    }
    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length !important;
    }
    .#{$abbrev}l-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}
.mx-auto,
.ml-auto {
  margin-left: auto !important;
}
.mx-auto,
.mr-auto {
  margin-right: auto !important;
}
.row-dragging-test-step {
  td {
    &:nth-child(1) {
      width: 60px;
    }
    &:nth-child(2) {
      width: 46px;
    }
    &:nth-child(3) {
      width: 48px;
    }
    &:last-child {
      width: 100px;
    }
  }
}
