body {
  font-size: 14px;
  color: $body-color;
}
body.dark {
  color: $body-dark-color;
}
a,
button {
  transition: all 0.4s ease;
}
a {
  color: $primary;
  &:hover {
    color: lighten($primary, 10%);
  }
  &:active {
    color: darken($primary, 10%);
  }
}
hr {
  border: 0;
  border-bottom: 1px solid $border-color;
}
