:root {
  --ck-color-base-border: #d9d9d9;
  --ck-color-base-active: #4285f4;
  --ck-color-base-active-focus: #2a75f3;
}

.ck-editor {
  background: #fff;
  .ck {
    &.ck-toolbar {
      + .ck-editor__editable_inline {
        border-top: 0;
      }
    }
    &.ck-editor__editable_inline {
      min-height: 160px;
      max-height: 400px;
      border: 1px solid $border-color;
    }
    .ck-file-dialog-button {
      display: none;
    }
  }
  .ck.ck-placeholder,
  .ck .ck-placeholder {
    &:before {
      color: #bfbfbf;
    }
  }
}
